import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useRouter } from 'next/router';
import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import parse, { domToReact } from 'html-react-parser';
import classNames from 'classnames';
import TranslationService from 'mfe-articles-shared/services/TranslationService/TranslationService';
import DataLayer from 'mfe-articles-shared/domains/DataLayer/DataLayer';
import styles from './Summary.module.scss';

const VIEWPORTS = {
    'x-small': 600,
    small: 768,
    large: 1025,
    'x-large': 1156,
};

const ANALYTICS_SELECTORS = {
    EXPAND_SUMMARY: 'expand-exec-summary-link',
};

const options = {
    replace: ({ type: typeOption, name, children }) => {
        if (typeOption === 'tag' && name === 'p') {
            return (
                <span className={styles['summary-text']}>
                    {domToReact(children)}
                </span>
            );
        }
        if (typeOption === 'tag' && name.includes('h')) {
            return (
                <h6 className={styles['summary-title']}>
                    {domToReact(children)}
                </h6>
            );
        }

        return null;
    },
};

function Summary() {
    const { summary, title, issue, type } = useArticleStore(
        (state) => state.article,
    );
    const articleType = type.replace(/\s+/g, '-');
    const { query } = useRouter();
    const contentType = issue ? 'Magazine Article' : 'Digital Article';
    const ts = new TranslationService(query?.language);

    const [croppedSummary, setCroppedSummary] = useState('');
    const [isSummaryCollapsed, setIsSummaryCollapsed] = useState(true);
    const [buttonText, setButtonText] = useState(ts.strings.more);
    const [updateHeight, setUpdateHeight] = useState(false);
    const [remainingSummary, setRemainingSummary] = useState('');
    const [characterLimit, setCharacterLimit] = useState(200); // Initial character limit
    const moreButtonRef = useRef(null);
    const remainingSummaryRef = useRef(null);

    // Calculate limit characters based on viewport size
    const getLimitCharacters = useCallback(() => {
        const width = window.innerWidth;
        if (width > VIEWPORTS['x-small'] && width < VIEWPORTS.small) return 220;
        if (width >= VIEWPORTS.small && width < VIEWPORTS.large) return 230;
        if (width >= VIEWPORTS.large && width < VIEWPORTS['x-large'])
            return 245;
        return width >= VIEWPORTS['x-large'] ? 260 : 200;
    }, []);

    // Set character limit on resize
    useEffect(() => {
        const handleResize = () => {
            setCharacterLimit(getLimitCharacters());
        };
        // Initial character limit calculation
        handleResize();
        // Add and clean up resize listener
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [getLimitCharacters]);

    useEffect(() => {
        if (summary) {
            const limit = characterLimit;
            const isSummaryValid = summary && summary.length > limit;

            if (isSummaryValid) {
                const lastSpaceIndex = summary.slice(0, limit).lastIndexOf(' ');
                setCroppedSummary(
                    parse(summary.slice(0, lastSpaceIndex), options),
                );
                setRemainingSummary(
                    parse(summary.slice(lastSpaceIndex), options),
                );
            }
        }
        setUpdateHeight(true);
    }, [summary, characterLimit]);

    const toggleParagraph = () => {
        const dataLayer = new DataLayer(window.digitalData);
        setIsSummaryCollapsed((prev) => {
            const isCollapsed = !prev;
            setButtonText(isCollapsed ? ts.strings.more : ts.strings.close);

            if (!isCollapsed) {
                dataLayer.setViewSummaryEvent(title, contentType);
                dataLayer.updateEvent();
                setTimeout(() => remainingSummaryRef.current?.focus(), 0);
            } else {
                setTimeout(() => moreButtonRef.current?.focus(), 0);
            }
            return isCollapsed;
        });
    };

    const containerClasses = classNames(styles.container, styles[articleType], {
        [styles['initial-layout']]: !updateHeight,
        [styles['final-layout']]: updateHeight,
    });

    if (!summary) return null;

    return (
        <section className={containerClasses}>
            <h2 className={styles['summary-header']}>
                {ts.strings.summary}
                .&nbsp;&nbsp;&nbsp;
            </h2>
            <span className={styles['summary-text']}>
                <span>{croppedSummary}</span>
                {!isSummaryCollapsed && (
                    <span
                        ref={remainingSummaryRef}
                        className={styles['summary-text']}
                        tabIndex="-1"
                    >
                        {remainingSummary}
                    </span>
                )}
            </span>
            {croppedSummary && (
                <span>
                    {isSummaryCollapsed && <span aria-hidden="true">...</span>}
                    <button
                        ref={moreButtonRef}
                        type="button"
                        className={styles.button}
                        onClick={toggleParagraph}
                        data-analytics={ANALYTICS_SELECTORS.EXPAND_SUMMARY}
                        aria-label={
                            isSummaryCollapsed
                                ? 'more summary information'
                                : 'less summary information'
                        }
                    >
                        {buttonText}
                    </button>
                </span>
            )}
        </section>
    );
}

export default Summary;
