import useArticleStore from 'mfe-articles-renderer/src/stores/article/article.store';
import classNames from 'classnames';
import Logo from 'mfe-core/ui/Logo';
import styles from './PrintLogo.module.scss';

function PrintLogo() {
    const { type } = useArticleStore((state) => state.article);

    const articleType = type.replace(/\s+/g, '-');

    const classes = classNames(styles['print-logo'], styles[articleType]);
    return <Logo className={classes} variant={Logo.variants.BIG_HBR_SHIELD} />;
}

export default PrintLogo;
